<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children":[
                  {
                    "key":"Root_MainPage_MobileHeader",
                    "type":"LhPageHeader",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":[

                    ],
                    "children":[
                      {
                        "key":null,
                        "type":"LhImage",
                        "actions":[
                          {
                            "url":"\/",
                            "type":"redirect",
                            "event":"click"
                          }
                        ],
                        "attributes":{
                          "src":"https:\/\/api.test.pharmis.technieken.be\/assets\/img\/logo.svg",
                          "collapse-src":"https:\/\/api.test.pharmis.technieken.be\/assets\/img\/logo_small.svg",
                          "class":"client-logo"
                        },
                        "styles":{
                          "cursor":"pointer"
                        },
                        "children":[

                        ]
                      }
                    ]
                  },
                  {
                    "key":"dashboard-page-title-group",
                    "type":"LhDiv",
                    "actions":[

                    ],
                    "attributes":{
                      "class":"page-title"
                    },
                    "styles":{
                      "display":"flex",
                      "flexDirection":"row",
                      "marginBottom":"24px"
                    },
                    "children":[
                      {
                        "key":"dashboard-page-title-one",
                        "type":"LhH1",
                        "actions":[

                        ],
                        "attributes":{
                          "content":"DASHBOARD_WELCOME"
                        },
                        "styles":[

                        ],
                        "children":[

                        ]
                      },
                      {
                        "key":"dashboard-page-title-two",
                        "type":"LhH1",
                        "actions":[

                        ],
                        "attributes":{
                          "content":"system"
                        },
                        "styles":{
                          "marginLeft":"6px"
                        },
                        "children":[

                        ]
                      }
                    ]
                  },
                  {
                    "key":"pharmacy-dashboard-main-row-2",
                    "type":"LhRow",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":{
                      "marginTop":"24px",
                      "marginBottom":"24px"
                    },
                    "children":[
                      {
                        "key":"dashboard-widget-col-1",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "sm":4,
                          "xs":12
                        },
                        "styles":{
                          "paddingTop":"1rem"
                        },
                        "children":[
                          {
                            "key":"dashboard-widget-1",
                            "type":"LhWidget",
                            "actions":[

                            ],
                            "attributes":{
                              "icon":"shopping-cart",
                              "stats": [
                                {
                                  "title":"DASHBOARD_SALES_TODAY",
                                  "data": [
                                    {
                                      "value": "\u20ac0002"
                                    },
                                    {
                                      "class": "text-red",
                                      "value": "\u20ac0003"
                                    },
                                    {
                                      "class": "text-green",
                                      "value": "\u20ac0004"
                                    }
                                  ],
                                },
                              ],
                              "type":"solid-icon-top",
                              "class":"type2__align-left"
                            },
                            "styles":[

                            ],
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-widget-col-2",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "sm":4,
                          "xs":12
                        },
                        "styles":{
                          "paddingTop":"1rem"
                        },
                        "children":[
                          {
                            "key":"dashboard-widget-2",
                            "type":"LhWidget",
                            "actions":[

                            ],
                            "attributes":{
                              "title":"DASHBOARD_WEEK_SALES",
                              "icon":"shopping-cart",
                              "stat":"\u20ac334.22",
                              "type":"type2",
                              "class":"type2__align-left"
                            },
                            "styles":[

                            ],
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-widget-col-3",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "sm":4,
                          "xs":12
                        },
                        "styles":{
                          "paddingTop":"1rem"
                        },
                        "children":[
                          {
                            "key":"dashboard-widget-3",
                            "type":"LhWidget",
                            "actions":[

                            ],
                            "attributes":{
                              "title":"DASHBOARD_MONTH_SALES",
                              "icon":"shopping-cart",
                              "stat":"\u20ac334.22",
                              "type":"type2",
                              "class":"type2__align-left"
                            },
                            "styles":[

                            ],
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-widget-col-4",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "sm":8,
                          "xs":12
                        },
                        "styles":{
                          "paddingTop":"1rem"
                        },
                        "children":[
                          {
                            "key":"dashboard-widget-4",
                            "type":"LhWidget",
                            "actions":[

                            ],
                            "attributes":{
                              "icon":"chart-line",
                              "class":"type2__align-left",
                              "type":"multi",
                              "stats":[
                                {
                                  "title":"DASHBOARD_STATS_YTD",
                                  "stat":"\u20ac133110.64"
                                },
                                {
                                  "title":"DASHBOARD_STATS_GROWTH",
                                  "stat":"-72.85%",
                                  "class":"text-red"
                                },
                                {
                                  "title":"DASHBOARD_STATS_GROWTH_VALUE",
                                  "stat":"\u20ac-357098.35"
                                }
                              ]
                            },
                            "styles":[

                            ],
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-widget-col-5",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "sm":4,
                          "xs":12
                        },
                        "styles":{
                          "paddingTop":"1rem"
                        },
                        "children":[
                          {
                            "key":"dashboard-widget-5",
                            "type":"LhWidget",
                            "actions":[

                            ],
                            "attributes":{
                              "title":"DASHBOARD_NEW_MESSAGES",
                              "icon":"envelope",
                              "stat":"0",
                              "type":"type2",
                              "class":"type2__align-left"
                            },
                            "styles":[

                            ],
                            "children":[

                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "key":"dashboard-visits-title-row",
                    "type":"LhRow",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":[

                    ],
                    "children":[
                      {
                        "key":"dashboard-visits-title-col-1",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "class":"dashboard-link-col"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-visits-title",
                            "type":"LhH2",
                            "actions":[

                            ],
                            "attributes":{
                              "content":"DASHBOARD_CHANGELOG",
                              "page-title":true
                            },
                            "styles":{
                              "marginTop":"24px",
                              "marginBottom":"24px"
                            },
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-visit-browse-link-col-2",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "class":"dashboard-mini-browse-controls"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-visit-browse-link-icon",
                            "type":"LhIcon",
                            "actions":[

                            ],
                            "attributes":{
                              "icon":"bars"
                            },
                            "styles":{
                              "marginRight":"12px"
                            },
                            "children":[

                            ]
                          },
                          {
                            "key":"dashboard-visit-browse-link",
                            "type":"LhA",
                            "actions":[

                            ],
                            "attributes":{
                              "href":"\/event_logs",
                              "content":"DASHBOARD_MORE"
                            },
                            "styles":{
                              "display":"inline-block",
                              "marginTop":"24px",
                              "marginBottom":"24px"
                            },
                            "children":[

                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "key":"dashboard-changelog-browse-empty",
                    "type":"LhP",
                    "actions":[

                    ],
                    "attributes":{
                      "content":"DASHBOARD_BROWSE_CHANGELOG_EMPTY"
                    },
                    "styles":[

                    ],
                    "children":[

                    ]
                  },
                  {
                    "key":"dashboard-orders-browse-link-col-1",
                    "type":"LhRow",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":[

                    ],
                    "children":[
                      {
                        "key":"dashboard-visits-title-col-1",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "class":"dashboard-link-col"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-orders-title",
                            "type":"LhH2",
                            "actions":[

                            ],
                            "attributes":{
                              "content":"DASHBOARD_LATEST_ORDERS",
                              "page-title":true
                            },
                            "styles":{
                              "marginTop":"24px",
                              "marginBottom":"24px"
                            },
                            "children":[

                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-orders-browse-link-col-2",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "class":"dashboard-mini-browse-controls"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-orders-browse-link-icon",
                            "type":"LhIcon",
                            "actions":[

                            ],
                            "attributes":{
                              "icon":"bars"
                            },
                            "styles":{
                              "marginRight":"12px"
                            },
                            "children":[

                            ]
                          },
                          {
                            "key":"dashboard-order-browse-link",
                            "type":"LhA",
                            "actions":[

                            ],
                            "attributes":{
                              "href":"\/visits",
                              "content":"DASHBOARD_MORE"
                            },
                            "styles":{
                              "display":"inline-block",
                              "marginTop":"24px",
                              "marginBottom":"24px"
                            },
                            "children":[

                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "key":"dashboard-orders-mini-browse-group",
                    "type":"LhDiv",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":[

                    ],
                    "children":[
                      {
                        "key":"dashboard-orders-browse-empty",
                        "type":"LhP",
                        "actions":[

                        ],
                        "attributes":{
                          "content":"DASHBOARD_BROWSE_ORDERS_EMPTY"
                        },
                        "styles":[

                        ],
                        "children":[

                        ]
                      }
                    ]
                  },
                  {
                    "key":"dashboard-statistics-overview-row",
                    "type":"LhRow",
                    "actions":[

                    ],
                    "attributes":[

                    ],
                    "styles":[

                    ],
                    "children":[
                      {
                        "key":"dashboard-statistics-overview-col-left",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "cols":"auto",
                          "md":8,
                          "class":"d-flex flex-column"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-sales-stat-group-",
                            "type":"LhDiv",
                            "actions":[

                            ],
                            "attributes":[

                            ],
                            "styles":{
                              "marginBottom":"2rem",
                              "paddingTop":"1rem",
                              "paddingBottom":"1rem",
                              "paddingLeft":"0.5rem",
                              "paddingRight":"0.5rem",
                              "border":"1px solid #6c757d"
                            },
                            "children":[
                              {
                                "key":"dashboard-sales-stat-control-row",
                                "type":"LhRow",
                                "actions":[

                                ],
                                "attributes":[

                                ],
                                "styles":[

                                ],
                                "children":[
                                  {
                                    "key":"dashboard-sales-stat-control-col-left",
                                    "type":"LhCol",
                                    "actions":[

                                    ],
                                    "attributes":{
                                      "cols":9
                                    },
                                    "styles":{
                                      "paddingLeft":"1.5rem"
                                    },
                                    "children":[
                                      {
                                        "key":"dashboard-sales-stat-title",
                                        "type":"LhH2",
                                        "actions":[

                                        ],
                                        "attributes":{
                                          "content":"DASHBOARD_WEEKLY_SALES_BY_REP"
                                        },
                                        "styles":{
                                          "display":"inline-block"
                                        },
                                        "children":[

                                        ]
                                      },
                                      {
                                        "key":"dashboard-sales-stat-date",
                                        "type":"LhP",
                                        "actions":[

                                        ],
                                        "attributes":{
                                          "content":"04-04-2022 - 08-04-2022"
                                        },
                                        "styles":{
                                          "display":"inline-block"
                                        },
                                        "children":[

                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key":"dashboard-sales-stat-control-col-right",
                                    "type":"LhCol",
                                    "actions":[

                                    ],
                                    "attributes":{
                                      "cols":3
                                    },
                                    "styles":[

                                    ],
                                    "children":[
                                      {
                                        "key":"dashboard-sales-stat-control-group",
                                        "type":"LhDiv",
                                        "actions":[

                                        ],
                                        "attributes":{
                                          "class":"d-flex flex-wrap justify-content-end"
                                        },
                                        "styles":[

                                        ],
                                        "children":[
                                          {
                                            "key":"dashboard--mini-browse-previous-1",
                                            "type":"LhIconButton",
                                            "actions":[
                                              {
                                                "url":"\/dashboardadmin",
                                                "type":"submit",
                                                "method":"POST",
                                                "event":"click"
                                              }
                                            ],
                                            "attributes":{
                                              "icon":[
                                                "fal",
                                                "angle-left"
                                              ],
                                              "value":-1
                                            },
                                            "styles":{
                                              "display":"inline-block"
                                            },
                                            "children":[

                                            ]
                                          },
                                          {
                                            "key":"dashboard--mini-browse-current-1",
                                            "type":"LhButton",
                                            "actions":[
                                              {
                                                "url":"\/dashboardadmin",
                                                "type":"submit",
                                                "method":"POST",
                                                "event":"click"
                                              }
                                            ],
                                            "attributes":{
                                              "content":"DASHBOARD_TODAY",
                                              "value":"0"
                                            },
                                            "styles":{
                                              "display":"inline-block",
                                              "marginTop":"10px"
                                            },
                                            "children":[

                                            ]
                                          },
                                          {
                                            "key":"dashboard--mini-browse-next-1",
                                            "type":"LhIconButton",
                                            "actions":[
                                              {
                                                "url":"\/dashboardadmin",
                                                "type":"submit",
                                                "method":"POST",
                                                "event":"click"
                                              }
                                            ],
                                            "attributes":{
                                              "icon":[
                                                "fal",
                                                "angle-right"
                                              ],
                                              "value":1
                                            },
                                            "styles":{
                                              "display":"inline-block"
                                            },
                                            "children":[

                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key":"dashboard-sales-week-by-rep-stats-1",
                                "type":"LhChart",
                                "actions":[

                                ],
                                "attributes":{
                                  "type":"Bar",
                                  "height":350,
                                  "chartdata":{
                                    "labels":[
                                      "Mon",
                                      "Tue",
                                      "Wed",
                                      "Thu",
                                      "Fri"
                                    ],
                                    "datasets":[

                                    ]
                                  },
                                  "options":{
                                    "responsive":true,
                                    "maintainAspectRatio":false,
                                    "animation":{
                                      "duration":500
                                    },
                                    "hover":{
                                      "animationDuration":500
                                    },
                                    "responsiveAnimationDuration":500,
                                    "legend":{
                                      "display":true,
                                      "fullWidth":true,
                                      "align":"center"
                                    },
                                    "scales":{
                                      "xAxes":[
                                        {
                                          "stacked":true,
                                          "gridLines":{
                                            "display":false
                                          }
                                        }
                                      ],
                                      "yAxes":[
                                        {
                                          "stacked":true,
                                          "ticks":{
                                            "beginAtZero":true
                                          },
                                          "gridLines":{
                                            "display":false
                                          }
                                        }
                                      ]
                                    }
                                  }
                                },
                                "styles":[

                                ],
                                "children":[

                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "key":"dashboard-statistics-overview-col-right",
                        "type":"LhCol",
                        "actions":[

                        ],
                        "attributes":{
                          "cols":"auto",
                          "md":4,
                          "class":"d-flex flex-column"
                        },
                        "styles":[

                        ],
                        "children":[
                          {
                            "key":"dashboard-top-products-stat-group",
                            "type":"LhDiv",
                            "actions":[

                            ],
                            "attributes":[

                            ],
                            "styles":{
                              "height":"100%",
                              "marginBottom":"2rem",
                              "paddingTop":"1rem",
                              "paddingBottom":"1rem",
                              "paddingLeft":"0.5rem",
                              "paddingRight":"0.5rem",
                              "border":"1px solid #6c757d"
                            },
                            "children":[
                              {
                                "key":"dashboard-top-products-stat-title",
                                "type":"LhH2",
                                "actions":[

                                ],
                                "attributes":{
                                  "content":"DASHBOARD_TOP_PRODUCTS"
                                },
                                "styles":{
                                  "marginBottom":"1rem",
                                  "paddingLeft":"0.5rem"
                                },
                                "children":[

                                ]
                              },
                              {
                                "key":"dashboard-top-products-stats",
                                "type":"LhChart",
                                "actions":[

                                ],
                                "attributes":{
                                  "type":"Doughnut",
                                  "height":350,
                                  "chartdata":{
                                    "labels":[
                                      "3M\u2122  Micropore\u2122 surgical tape dispenser 2.5 cm x 915cm",
                                      "loaded for historical sales on dropped products",
                                      "3M\u2122  Micropore\u2122  surgical tape dispenser 1.25 cm x 915cm"
                                    ],
                                    "datasets":[
                                      {
                                        "data":[
                                          1005197.77,
                                          275718.85,
                                          189958.25
                                        ],
                                        "backgroundColor":[
                                          "#5572BB",
                                          "#01B373",
                                          "#444444",
                                          "#90AFFF",
                                          "#00F39B",
                                          "#42588D",
                                          "#007349",
                                          "#6B8CDD",
                                          "#00D789",
                                          "#A1A1A1",
                                          "#FFCC00",
                                          "#005E60",
                                          "#D5A400",
                                          "#74A9AD",
                                          "#E43738",
                                          "#7AC04C",
                                          "#9D9D9C",
                                          "#F28A2E",
                                          "#4A78FF",
                                          "#DA19FF",
                                          "#FF6140",
                                          "#A75CA6",
                                          "#00FFE7",
                                          "#77C7D9",
                                          "#C5FF33",
                                          "#576618",
                                          "#F2A7A0",
                                          "#53AA7E",
                                          "#094593",
                                          "#5E1DB9",
                                          "#693C05",
                                          "#D77640",
                                          "#9E8F6C",
                                          "#73262C",
                                          "#B6DBF2",
                                          "#468966",
                                          "#FFF0A5",
                                          "#FF556E",
                                          "#375D81",
                                          "#000000"
                                        ],
                                        "borderWidth":10,
                                        "borderColor":"#ffffff",
                                        "hoverBorderColor":"#ffffff"
                                      }
                                    ]
                                  },
                                  "options":{
                                    "responsive":true,
                                    "maintainAspectRatio":false,
                                    "animation":{
                                      "duration":500
                                    },
                                    "hover":{
                                      "animationDuration":500
                                    },
                                    "responsiveAnimationDuration":500,
                                    "legend":{
                                      "display":true,
                                      "fullWidth":true,
                                      "align":"center",
                                      "position":"bottom"
                                    },
                                    "scales":{
                                      "xAxes":[
                                        {
                                          "ticks":{
                                            "beginAtZero":true,
                                            "display":false
                                          },
                                          "gridLines":{
                                            "display":false
                                          }
                                        }
                                      ],
                                      "yAxes":[
                                        {
                                          "ticks":{
                                            "beginAtZero":true,
                                            "display":false
                                          },
                                          "gridLines":{
                                            "display":false
                                          }
                                        }
                                      ]
                                    }
                                  }
                                },
                                "styles":[

                                ],
                                "children":[

                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
