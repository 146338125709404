<template>
  <li class="nav-list-item"
      :class="[checkClasses, {collapsed: collapsed}]"
      :style="checkStyles"
      v-tooltip="checkTooltip"
      @click.stop="handleClick"
      :id="obj.key"
      :key="'li' + obj.key"
      v-on-clickaway="closePopover"
  >
    <div class="navlist-item-router-link-wrapper">
      <router-link
          :to="{path: obj.attributes.action}"
          :class="[{'is-active': isChildActive || isCurrentlyActive}]"
          :event="clickDisabled ? '' : 'click'"
          :key="obj.key"
      >
        <div class="navlist-item-icon">
          <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                             :icon="obj.attributes.icon"
          ></font-awesome-icon>
        </div>
        <div class="label" v-show="!collapsed || isMobile">{{$t(obj.attributes.content)}}<lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
        </div>
      </router-link>
      <div class="navlist-item-icon navlist-collapse-icon" v-if="collapseSubNav" @click.stop="handleCollapseNav">
        <transition :name="rotateMode" mode="out-in">
          <font-awesome-icon v-if="subnavCollapsed" :icon="['fal', 'angle-right']"             :key="'icon-down'"
          ></font-awesome-icon>
          <font-awesome-icon v-else :icon="['fal', 'angle-down']"             :key="'icon-up'"
          ></font-awesome-icon>
        </transition>
      </div>
    </div>
    <template v-if="collapseSubNav || (obj.children.length && popover)">
      <transition name="fade">
        <div :id="'lh-collapse-nav' + obj.key" v-if="!subnavCollapsed || popover">
          <template v-if="!collapsed || (obj.children.length && isMobile) || (obj.children.length && popover)"
                    v-for="item in obj.children">
            <component :is="item.type" :obj="item" :key="item.key" class="sub-nav" :parentNavList="parentNavKeys"></component>
          </template>
        </div>
      </transition>
    </template>
    <template v-else>
      <template v-if="(obj.children.length && !collapsed) || (obj.children.length && isMobile)"
                v-for="item in obj.children" :obj="item">
        <component :is="item.type" :obj="item" :key="item.key" :parentNavList="parentNavKeys"></component>
      </template>
    </template>
    <b-popover
        ref="popover"
        :target="obj.key"
        triggers="manual"
        placement="rightbottom"
        :container="null"
        boundary="viewport"
    >
      <template v-slot:title>
        <div @click="handleClick(true)">
          <router-link
              :to="{path: obj.attributes.action}"
              class="popover-nav-title"
          >
            <div class="navlist-item-icon">
              <font-awesome-icon v-if="checkEmpty(obj.attributes.icon)"
                                 :icon="obj.attributes.icon"
              ></font-awesome-icon>
            </div>
            <div class="label">{{$t(obj.attributes.content)}}<lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
            </div>
          </router-link>
        </div>
      </template>
      <template v-for="item in obj.children" :obj="item">
        <component :is="item.type" :obj="item" :key="item.key" :parentNavList="parentNavKeys" :popover="true"></component>
      </template>
    </b-popover>
  </li>
</template>

<script>
  import { mapState } from 'vuex'
  import { mixin as clickaway } from 'vue-clickaway'
  import { eventBus } from '../services/event-bus'


  export default {
    name: 'LhNavListItem',
    props: {
      obj: {
        type: Object
      },
      collapseOthers: {
        boolean: Boolean
      },
      parentNavList: {
        type: Array
      },
      popover: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        popoverVisible: false,
        subnavCollapsed: false,
        parentNavKeys: []
      }
    },
    mixins: [clickaway],
    computed: {
      ...mapState({
        collapsed: 'sidebarCollapse',
        isMobile: 'isMobile'
      }),
      isChildActive () {
        return this.obj.children.some(child => child.children.some(grandchild => grandchild.attributes.action === this.$route.path))
      },
      isCurrentlyActive () {
        return this.obj.attributes.action === this.$route.path
      },
      clickDisabled () {
        return !this.isMobile && this.collapsed && this.obj.children.length
      },
      collapseSubNav () {
        return this.obj.children.length > 0 && this.obj.attributes.collapseSubNav && !this.collapsed
      },
      rotateMode () {
        return this.subnavCollapsed ? 'rotateRight90' : 'rotateLeft90'
      },
    },
    methods: {
      handleClick (fromPopover) {
        this.$store.commit('setLastRoutes', this.obj.attributes.action)
        if (!this.isMobile && this.collapsed && this.obj.children.length) {
          this.$root.$emit('bv::hide::popover')
          this.$refs.popover.$emit('open')
        } else if(this.$route.path === this.obj.attributes.action) {
          this.$store.dispatch('reloadPage', this.$route.fullPath)
        }
        if (fromPopover == true && this.$route.path === this.obj.attributes.action) {
          this.$store.dispatch('reloadPage', this.$route.fullPath)
        }
        this.handleCollapseNav(true)
      },
      closePopover () {
        if (this.obj.children.length) {
          this.$refs.popover.$emit('close')
        }
      },
      handleCollapseNav (fromNavItemClick) {
        if (fromNavItemClick === true) {
          this.subnavCollapsed = false
        } else {
          this.subnavCollapsed = !this.subnavCollapsed
        }
        if (!this.subnavCollapsed && this.collapseOthers) {
          eventBus.$emit('collapseOtherNavs', this.parentNavKeys)
        }
      },
      handleCollapseAllNav (parentNavKeys) {
        console.log(parentNavKeys)
        if (!parentNavKeys.includes(this.obj.key)) {
          this.subnavCollapsed = true
        }
      }
    },
    watch: {
      $route (to, from) {
        if (this.obj.children.length) {
          this.$refs.popover.$emit('close')
        }
      }
    },
    created () {
      this.subnavCollapsed = this.obj.attributes.collapseSubNav
      this.parentNavKeys = this.parentNavList ? JSON.parse(JSON.stringify(this.parentNavList)) : []
      this.parentNavKeys.push(this.obj.key)
      eventBus.$on('collapseOtherNavs')
    },
    mounted () {
      if (this.isChildActive) this.subnavCollapsed = false
    },
    beforeDestroy () {
      eventBus.$off('collapseOtherNavs')
    }
  }
</script>

<style lang="scss">
  $nav-indent: 1rem;
  .nav-list-item {
    font-size: 1.3rem;
    font-weight: 700;
    padding: 0.5rem 0;
    overflow: hidden;
    max-width: 100%;
    opacity: 1;
    /*overflow: visible;*/

    @include media-breakpoint-up(md) {
      max-width: $sidebar-max-width;
      width: $sidebar-max-width;
      &.collapsed {
        .label {
          opacity: 0;
        }
      }
      font-size: 1.15rem;
    }

    &:first-child {
      padding-top: 0;
    }

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      color: $medium-grey-alt;
      text-decoration: none;
      width: 100%;

      .label {
        border-bottom: 1px solid transparent;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        hyphens: auto;
        max-width: calc(100% - 48px);
        display: block;
      }

      &.router-link-exact-active, &.is-active {
        color: $nav-active-link !important;

        &:hover, &:focus {
          color: darken($nav-active-link, 10%) !important;
        }

        .label {
          border-bottom-color: $nav-active-underline;
        }
      }

      &:hover {
        color: $medium-dark-grey;
        text-decoration: none;
      }
    }

    & li {
      font-weight: 300;
      padding-top: 0.2rem;
      font-size: 1rem;

      &:first-child {
        padding-top: 1rem;
      }

      a {
        &.router-link-active {
          .label {
            border-bottom-color: transparent;
          }
        }
      }
    }

    .navlist-item-icon {
      min-width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.navlist-collapse-icon {
        min-width: 2.5rem;
        cursor: pointer;
        font-size: 1.4rem;
        color: $medium-dark-grey;
        position: absolute;
        left: -22px;
        top: 3px;

        .sub-nav & {
          left: 8px;
          top: 1px;
        }
      }
    }

    .navlist-item-router-link-wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
    }
  }
  .popover {
    .nav-list-item {
      a {
        display: block;
        .label {
          display: inline-block !important;
          max-width: 100%;
          opacity: 1;
        }
        &.router-link-exact-active, &.is-active {
          .label {
            border-bottom: none;
          }
        }
      }
    }

    .nav-list .navlist-item-router-link-wrapper a{
      width: 100%;
    }

    .popover-nav-title {
      text-decoration: none;
    }
  }
</style>
