<template>
  <ValidationProvider :vid="obj.attributes.name" :name="$t(fieldName)"
                      :rules="checkRules ? rulesObj :
                      typeof obj.attributes.validation == 'string' ? obj.attributes.validation : ''">
    <div slot-scope="{ valid, errors }" v-bind="obj.attributes" class="lh-input" :class="[checkClasses, {isTouch: isTouch}]"
         :style="checkStyles" @click.stop="emitClick" @touchstart="setTouch">
      <transition name="fade">
        <div :class="['form-group-wrapper', {'remove-margin-bottom': obj.attributes.typeInfo || obj.attributes.noMargin}, {'hidden': obj.attributes.type == 'hidden'}]">
          <b-form-group
              :label-class="setLabel == '' ? 'empty-space-placeholder' : ''"
              :label-for="obj.attributes.id"
              :label-cols-md="obj.attributes.labelCols"
              :key="obj.attributes.id"
              v-show="obj.attributes.type.toLowerCase().trim() !== 'hidden'"
              :label-sr-only="obj.attributes.type.toLowerCase().trim() === 'checkbox' && !obj.attributes.labelPlaceholder"
          >
            <template slot="label" v-show="obj.attributes.hasOwnProperty('label')">
              {{ setLabel }}
              <span v-if="obj.attributes.labelIcon" class="label-icon">
                <font-awesome-icon :icon="obj.attributes.labelIcon.icon ? obj.attributes.labelIcon.icon : 'info-circle'" v-tooltip="labelTooltip"></font-awesome-icon>
              </span>
              <lh-edit-translation :obj="{key: obj.attributes.label, parentKey: obj.key}"></lh-edit-translation>
            </template>


            <!-- DATE-->
            <div v-if="obj.attributes.type.toLowerCase().trim() === 'date' || obj.attributes.type.toLowerCase().trim() === 'date-button'" class="input-wrapper">
              <DateRangePicker
                  :id="obj.attributes.id"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :error="errors[0] ? 'help' : 'correct'"
                  :obj="obj"
                  :attr="obj.attributes"
                  :passed="passed"
                  :hasValue="innerValue ? true : false"
                  @selected="handleChangeDate"
                  @input="handleChangeDate"
                  v-model="dateValue"
                  @onEnter="onEnter"
              ></DateRangePicker>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- DATERANGE -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'daterange'" class="input-wrapper">
              <DateRangePicker
                  :id="obj.attributes.id"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :yearFirst="obj.attributes.yearFirst"
                  :placeholder="obj.attributes.placeholder ? obj.attributes.placeholder : 'dd/mm/jjjj - dd/mm/jjjj'"
                  :obj="obj"
                  :hasValueRange="innerValue"
                  ref="picker"
                  @selected="handleDateRange"
                  @onEnter="onEnter"
              ></DateRangePicker>
              <div class="date-range-children" v-if="obj.attributes.timepicker">
                <p>{{ $t('GENERAL_TIME_FROM') }}</p>
                <vue-timepicker format="HH:mm"
                                :minute-interval="5"
                                @change="handleStartTime"
                                auto-scroll
                                hour-label="uur"
                                minute-label="min"
                                :disabled="obj.attributes.startTime.disabled"
                                :key="obj.attributes.id + '-starttime'"
                ></vue-timepicker>
                <p>{{ $t('GENERAL_TIME_TO') }}</p>
                <vue-timepicker format="HH:mm"
                                :minute-interval="5"
                                @change="handleEndTime"
                                auto-scroll
                                hour-label="uur"
                                minute-label="min"
                                :disabled="obj.attributes.endTime.disabled"
                                :key="obj.attributes.id + '-endtime'"
                ></vue-timepicker>
              </div>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- TIME -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'time'" class="input-wrapper">
              <vue-timepicker format="HH:mm"
                              :minute-interval="5"
                              v-model="innerValue"
                              advanced-keyboard
                              auto-scroll
                              hour-label="uur"
                              minute-label="min"
                              @input="handleChange('change')"
                              :placeholder="obj.attributes.placeholder"
                              :disabled="checkDisabled"
                              manual-input
                              @keyup.enter="onEnter"
              ></vue-timepicker>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- RANGE -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'range'" class="input-wrapper">
              {{ innerValue }}
              <b-form-input
                  :id="obj.attributes.id"
                  v-model="innerValue"
                  :type="obj.attributes.type"
                  :max="obj.attributes.max"
                  :min="obj.attributes.min"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? 'help' : 'correct'"
                  @input="handleChange('change')"
                  :disabled="checkDisabled"
                  :autocomplete="checkAutocomplete"
                  @keyup.enter="onEnter"
              ></b-form-input>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- TEXTAREA -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'textarea'" class="input-wrapper">
              <b-form-textarea
                  :id="obj.attributes.id"
                  v-model="innerValue"
                  :rows="obj.attributes.rows"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? 'help' : 'correct'"
                  @input="handleChange('change')"
                  :style="obj.attributes.inputStyle ? obj.attributes.inputStyle : null"
                  :disabled="checkDisabled"
                  @focus="handleFocus"
                  :ref="obj.attributes.id"
                  :autocomplete="checkAutocomplete"
                  @keyup.enter="onEnter"
              ></b-form-textarea>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- CHECKBOX -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'checkbox-group' || (obj.attributes.type.toLowerCase().trim() === 'checkbox' && obj.children.length > 0)" @keyup.enter="onEnter" class="input-wrapper"
            >
              <b-form-checkbox-group
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? '' : 'correct'"
                  :id="obj.attributes.id"
                  :name="obj.attributes.id"
                  v-model="innerValue"
                  :stacked="obj.attributes.stacked"
                  :disabled="checkDisabled"
                  @input="handleChange('change')"
                  @focus.native="handleFocus"
                  :ref="obj.attributes.id"
                  :switches="obj.attributes.switchCheckbox"
              >
                <b-form-checkbox
                    v-for="checkbox in obj.children"
                    :value="checkbox.attributes.value"
                    size="lg"
                    :disabled="checkbox.attributes.disabled === true || checkbox.attributes.disabled === 'true'"
                    :checked="checkbox.attributes.checked"
                >
                  {{ $t(checkbox.attributes.content) }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

<!--            single checkbox: -->
<!--            - obj.attributes.value = checked value-->
<!--            - obj.attributes.checked = current value (fill with value or false for checked or unchecked)-->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'checkbox'" @keyup.enter="onEnter" class="input-wrapper">
              <b-form-checkbox-group
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? '' : 'correct'"
                  :id="obj.attributes.id"
                  :name="obj.attributes.id"
                  v-model="innerValue"
                  :stacked="obj.attributes.stacked"
                  :disabled="checkDisabled"
                  @input="handleChange('change')"
                  @focus.native="handleFocus"
                  :ref="obj.attributes.id"
                  :switches="obj.attributes.switchCheckbox"
              >
                <b-form-checkbox
                    :value="obj.attributes.value"
                    size="lg"
                    :disabled="checkDisabled"
                    :checked="obj.attributes.checked"
                >
                  {{ $t(obj.attributes.content) }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- RADIO -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'radio'" @keyup.enter="onEnter" class="input-wrapper">
              <b-form-radio-group
                  v-model="innerValue"
                  :id="obj.attributes.id"
                  :name="obj.attributes.id"
                  :stacked="obj.attributes.stacked === 'true' || obj.attributes.stacked === true"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? 'help' : 'correct'"
                  class="radio-group-wrapper"
                  :disabled="checkDisabled"
                  @input="handleChange('change')"
                  @focus.native="handleFocus"
                  :ref="obj.attributes.id"
              >
                <b-form-radio
                    v-for="radio in obj.children"
                    size="lg"
                    :value="radio.attributes.value"
                    :disabled="radio.attributes.disabled === true || radio.attributes.disabled === 'true'"
                    :checked="radio.attributes.checked"
                >
                  {{ $t(radio.attributes.content) }}
                </b-form-radio>
              </b-form-radio-group>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- SELECT -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'select'" class="input-wrapper" @keyup.prevent.enter="onEnter" @keydown.prevent.enter @keypress.prevent.enter>
              <!-- b-form-select support grouped options beyond only 1 level deep-->
              <div class="lh-select">
                <b-form-select
                    :id="obj.attributes.id"
                    v-model="innerValue"
                    :options="updatedContent"
                    @input="handleSelectChange(innerValue)"
                    :placeholder="obj.attributes.placeholder"
                    :disabled="checkDisabled"
                    :class="errors[0] ? 'help' : 'correct'"
                    v-on="allEvents"
                    @focus.native="handleFocus"
                    :ref="obj.attributes.id"
                    :autocomplete="checkAutocomplete"
                >
                </b-form-select>
              </div>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- AUTOSUGGEST -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'autosuggest'" class="input-wrapper">
              <auto-suggest
                  v-model="innerValue"
                  :isAsync="true"
                  :obj="obj"
                  @handleInput="handleAutosuggest"
                  @handleSearchedItem="handleSearchedItem"
                  @onEnter="onEnter"
                  :class="errors[0] ? 'help' : 'correct'"
              ></auto-suggest>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- FILE -->
            <template v-else-if="obj.attributes.type.toLowerCase().trim() === 'file'">
              <lh-upload
                  v-model="innerValue"
                  :isInput="true"
                  :obj="obj"
                  @handleInput="handleFileChange"
                  :class="errors[0] ? 'help' : 'correct'"
                  @click.native="handleFocus"
                  :ref="obj.attributes.id"
                  @keyup.enter="onEnter"
              ></lh-upload>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </template>

            <!-- COLORPICKER -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'colorpicker'" class="input-wrapper">
              <color-picker
                  v-model="innerValue"
                  @handleChange="handleColorChange"
                  :disabled="checkDisabled"
                  v-on="allEvents"
                  :obj="obj"
                  :ref="obj.attributes.id"
                  @keyup.enter="onEnter"
              ></color-picker>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- BUSINESSHOUR -->
            <template v-else-if="obj.attributes.type.toLowerCase().trim() === 'businesshour'">
              <lh-business-hours
                  :isInput="true"
                  :obj="obj"
                  @handleChange="handleBusinessHourChange"
                  :class="errors[0] ? 'help' : 'correct'"
                  :ref="obj.attributes.id"
                  @keyup.enter="onEnter"
              ></lh-business-hours>
            </template>

            <!-- INPUTTAGS -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() === 'inputtags'" class="input-wrapper">
              <b-form-tags :input-id="obj.attributes.id"
                           v-model="innerValue"
                           class="lh-input__input-tags"
                           :state="errors[0] ? false : (passed ? true : null)"
                           :limit="100"
                           :tag-validator="tagValidator"
                           :name="obj.attributes.name"
                           :input-type="rulesObj.email ? 'email' : 'text'"
                           @tag-state="setTagValidation"
                           @input="handleChange('change')"
                           :autofocus="obj.attributes.focusOnLoad"
              >
                <template v-slot="{ tags, inputId, inputAttrs, inputHandlers, addTag, removeTag }">
                  <b-input-group aria-controls="my-custom-tags-list">
                    <input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :disabled="checkDisabled"
                        :placeholder="$t(obj.attributes.placeholder)"
                        class="form-control">
                    <b-input-group-append>
                      <b-button @click="addTag()" class="button secondary">{{ $t('GENERAL_ADD') }}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <span v-if="tagValidation.length" class="error-message pl-2 pt-5">{{ tagValidation }}</span>
                  <ul :class="[{'has-error': errors && errors.length > 0}]" v-if="tags.length > 0">
                    <b-card v-for="tag in tags" :key="tag" :id="tag" tag="li" class="mt-1 mr-1"
                            body-class="py-1 pr-2"
                    >
                      <strong>{{ tag }}</strong>
                      <b-button @click="removeTag(tag)" variant="link" size="sm">{{ $t('GENERAL_REMOVE') }}
                      </b-button>
                    </b-card>
                  </ul>
                </template>
              </b-form-tags>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- INPUT -->
            <div v-else-if="obj.attributes.type.toLowerCase().trim() !== 'hidden'" class="input-wrapper">
              <b-form-input
                  :id="obj.attributes.id"
                  v-model="innerValue"
                  v-bind="obj.attributes"
                  :state="errors[0] ? false : (passed ? true : null)"
                  :class="errors[0] ? 'help' : 'correct'"
                  @input="handleChange('change')"
                  :disabled="checkDisabled"
                  :placeholder="$t(obj.attributes.placeholder)"
                  v-on="allEvents"
                  @focus="handleFocus"
                  :ref="obj.attributes.id"
                  :autocomplete="checkAutocomplete"
                  @keyup.enter="onEnter"
              ></b-form-input>
              <span class="input-icon-slot" v-if="obj.attributes.iconButtons">
                <component v-for="btn in obj.attributes.iconButtons" :is="btn.type" :key="btn.key" :obj="btn"></component>
              </span>
            </div>

            <!-- HIDDEN -->
            <template v-else v-show="obj.attributes.type.toLowerCase().trim() !== 'hidden'">
              <input
                  v-model="innerValue"
                  v-bind="obj.attributes"
                  :state="errors[0] ? false : (valid ? true : null)"
                  @input="handleChange('handleChange')"
                  :class="[errors[0] ? 'help' : 'correct', 'hidden']"
                  :disabled="checkDisabled"
                  v-on="allEvents"
                  :ref="obj.attributes.id"
                  autocomplete="chrome-off"
              />
            </template>
            <template slot="description" v-show="obj.attributes.description">
              {{ $t(obj.attributes.description) }}
              <lh-edit-translation :obj="{key: obj.attributes.description, parentKey: obj.key}"></lh-edit-translation>
            </template>
          </b-form-group>

          <b-form-row v-if="obj.attributes.extraInfo">
            <b-col v-if="parseFloat(obj.attributes.labelCols) > 0 && parseFloat(obj.attributes.labelCols) < 12" :md="obj.attributes.labelCols"></b-col>
            <b-col><lh-input-extra-info :obj="obj.attributes.extraInfo" :value="this.innerValue"></lh-input-extra-info></b-col>
          </b-form-row>
          <span v-show="errors.length > 0" v-for="(error, index) in errors" class="error-message" :key="error + index">{{
              error
            }}</span>
          <span  v-for="warning in warnings" class="warning">
            {{ $t(warning) }}
          </span>
        </div>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import DateRangePicker from './custom/DateRangePicker'
import ColorPicker from './custom/ColorPicker'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import AutoSuggest from './LhAutoSuggest'
import LhUpload from './LhUpload'
import LhBusinessHours from './LhBusinessHours'
import moment from 'moment'
import { eventBus } from '../services/event-bus'
import mixinFunctions from '@/mixin/mixinFunctions'
import confirm from '@/mixin/confirm'

export default {
  name: 'LhInput',
  mixins: [mixinFunctions, confirm],
  components: {
    LhUpload,
    ValidationProvider,
    DateRangePicker,
    AutoSuggest,
    VueTimepicker,
    ColorPicker,
    LhBusinessHours
  },
  props: {
    obj: {
      type: Object
    },
    parentKey: {
      type: String
    },
    customLabel: {
      type: String
    }
  },
  data () {
    return {
      innerValue: null,
      parentFormKey: '',
      dateRangeValue: {
        startDate: '',
        endDate: ''
      },
      dateRangeData: {
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: this.$t('Confirm'),
        cancelLabel: this.$t('Cancel'),
      },
      rulesObj: {},
      isTouch: false,
      tagValidation: '',
      warnings: [],
      tempObj: {"key":"pharmacy-edit-button-5","type":"LhIconButton","actions":[{"url":"/pharmacies/detail/9141b779ecd90a4372cee15573c3ae5780bbd6a2a061ce18e52490a80f8c37cda8f58723db09b371c4f0510e832a63ee62d8528664720ec5377439fad6efc5d9","type":"update","method":"GET","event":"click"}],"attributes":{"content":"PHARMACY_BROWSE_DETAIL","icon":"globe"},"styles":[],"children":[]}
    }
  },
  methods: {
    handleChange (event) {
      console.log('input handle change: ', this.innerValue.length)
      let inputValue = this.innerValue
      if (this.obj.attributes.type.toLowerCase().trim() === 'checkbox' && this.obj.children.length === 1 && parseFloat(this.obj.children[0].attributes.value) === 1) {
        if (parseFloat(this.innerValue) === 0) inputValue = false
        else inputValue = this.innerValue.length ? '1' : false
      }

      let payload = {
        key: this.parentFormKey,
        inputs: {
          [this.obj.attributes.name]: inputValue
        }
      }
      this.$store.commit('setFormFilledDataByKey', payload)
      this.emitToForm('handleChange', inputValue)
      if (this.allEvents) {
        for (let actionEvent in this.allEvents) {
          console.log(actionEvent)
          if (typeof actionEvent === 'function' && actionEvent == event) {
            console.log('execute action for input')
          }
        }
      }
      // this.replaceComponentFeatures(this.obj.attributes.content)
      if (this.obj.attributes.warningValidation) {
        this.validateWarning()
      }
    },
    handleChangeDate (date) {
      if (date == '') {
        this.innerValue = ''
      } else {
        if (this.obj.attributes.timepicker) {
          this.innerValue = moment(date).format('YYYY-MM-DDTHH:mm:ss')
        } else {
          this.innerValue = moment(date).format('YYYY-MM-DDT00:00:00')
        }
      }
      this.handleChange('change')
      if (this.obj.attributes.submitOnChange) {
        eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
      }
    },
    handleAutosuggest (result) {
      this.innerValue = result
      this.handleChange('change')
      if (this.obj.attributes.submitOnChange) {
        eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
      }
    },
    handleSearchedItem (searchedItem) {
      //Pass on
      this.$emit('handleSearchedItem', searchedItem)
    },
    handleDateRange (date) {
      console.log('handling daterange: ', date)
      let start = date.startDate
      let end = date.endDate
      if (date.length === 0) {
        this.innerValue = ''
      } else {

        if (this.obj.attributes.timepicker) {
          console.log('formatting dateTime')
          this.innerValue = Object.assign({}, this.innerValue, {
            start: start ? moment(date.startDate).format('YYYY-MM-DDTHH:mm:ss') : '',
            end: end ? moment(date.endDate).format('YYYY-MM-DDTHH:mm:ss') : ''
          })
        } else {
          this.innerValue = Object.assign({}, this.innerValue, {
            start: start ? moment(date.startDate, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00') : '',
            end: end ? moment(date.endDate, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00') : ''
          })
        }
      }
      this.handleChange('change')
      if (this.obj.attributes.submitOnChange) {
        eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
      }
    },
    handleStartTime (change) {
      console.log(JSON.stringify(this.innerValue))
      this.innerValue = Object.assign({}, this.innerValue, { startTime: { h: change.data.HH, m: change.data.mm } })
      this.handleChange('change')
    },
    handleEndTime (change) {
      this.innerValue = Object.assign({}, this.innerValue, { endTime: { h: change.data.HH, m: change.data.mm } })
      this.handleChange('change')
    },
    handleColorChange (val) {
      this.innerValue = val
      this.handleChange('change')
    },
    handleFileChange (result) {
      this.innerValue = result
      this.handleChange('change')
    },
    handleSelectChange (val) {
      this.handleSelectChangeAction(val)
      if (this.obj.attributes.submitOnChange) {
        eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
      }
    },
    handleBusinessHourChange (val) {
      this.innerValue = JSON.stringify(val)
      this.handleChange('change')
    },
    handleInputTagChange (result) {
      this.innerValue = result
      this.handleChange('change')
    },
    handleSelectChangeAction (val) {
      this.innerValue = val

      let content = this.obj.attributes.content
      let payload = {
        key: this.parentFormKey,
        inputs: {}
      }

      if (Array.isArray(content)) {
        let option = {}
        let index = content.map(e => e.value).indexOf(val)
        if (index == -1) {
          //search selected option in nested content if not found
          //grouped select content structure has to be object of array to work
          const searchValue = (arr) => (
              arr.reduce((a, item) => {
                if (a) return a
                if (item.value === val) return item
                if (item['content']) return searchValue(item['content'])
              }, null)
          )
          option = searchValue(content)
        } else {
          option = content[index]
        }
        payload['inputs'] = {
          [this.obj.attributes.name]: option
        }
        if (option.hasOwnProperty('text')) {
          this.emitToForm('handleChange', option.text, option)
        }
      } else {
        // if object
        payload['inputs'] = {
          [this.obj.attributes.name]: {
            text: content[val],
            value: val
          }
        }
        let option = content[val]
        this.emitToForm('handleChange', option)
      }
      this.$store.commit('setFormFilledDataByKey', payload)

      //handle submit for amount pages dropdown
      if (this.obj.attributes.target) {
        let payload = {}
        let type = ''
        if (this.obj.attributes.typeInfo) {
          //typeInfo: uses value of this select to add a property to browsesetting.
          //example-> typeInfo = 'browseSize' in this case for the amount of rows in a browse
          type = this.obj.attributes.typeInfo
        }
        payload[type] = val

        this.$store.dispatch('setBrowseSettingByKey', {
          key: this.obj.attributes.target,
          payload: payload
        })

        this.$store.commit('setPaginationData', {
          key: this.obj.attributes.target,
          payload: payload
        })

        this.$store
            .dispatch('sortAndFilter', {
              target: this.obj.attributes.target,
              url: this.obj.attributes.url,
              browseKey: this.obj.attributes.target,
              pressedBtn: this.obj
            })
            .then(result => {
              // this.$store.commit("setMainJSON", result);
              // this.replaceTarget(result)
            })
      }
    },
    setCustomMessages () {
      let validations = this.obj.attributes.validation

      let currentFieldName = this.$t(this.obj.attributes.label)
      let fieldName = this.fieldName
      let dictionary = {
        custom: {
          [fieldName]: {}
        }
      }
      if (validations && Array.isArray(validations)) {
        for (let validation of validations) {
          let translation = validation.translation_key_custom
              ? this.$t(validation.translation_key_custom)
              : this.$t(validation.translation_key)

          function ruleMsg (currentFieldName, { translation }) {
            if (translation.includes('{field}')) {
              return translation.replace('{field}', currentFieldName)
            } else {
              return translation
            }
          }

          if (validation.type.includes(':')) {
            let confirm = validation.type.split(':')
            this.$set(this.rulesObj, confirm[0], confirm[1])
            dictionary.custom[fieldName][confirm[0]] = () => ruleMsg(currentFieldName, { translation })
          } else {
            this.$set(this.rulesObj, validation.type, true)
            dictionary.custom[fieldName][validation.type] = () => ruleMsg(currentFieldName, { translation })
          }
        }
        this.$validator.localize({
          [this.$i18n.locale]: dictionary
        })
      }
    },
    emitToForm (event, emitValue, selectValue) {
      let name = this.obj.attributes.name
      let value = emitValue
      let parent = this.obj.attributes.parent
      let key = this.obj.key
      eventBus.$emit('inputEmit' + this.parentFormKey, { event, value, name, parent, key, selectValue })
    },
    handleClearInput () {
      console.log('clearing input')
      if (this.obj.attributes.type.toLowerCase().trim() !== 'hidden') {
        if (this.obj.attributes.type.toLowerCase().trim() === 'file') {
          this.innerValue = ''
        } else if (this.obj.attributes.type.toLowerCase().trim() === 'checkbox') {
          this.innerValue = []
        } else if (['time', 'autosuggest'].includes(this.obj.attributes.type.toLowerCase().trim())) {
          this.innerValue = {}
        } else if (['date', 'daterange'].includes(this.obj.attributes.type.toLowerCase().trim())) {
          this.innerValue = ''
        } else {
          this.innerValue = ''
        }
        this.handleChange()
      }
    },
    handleFocus () {
      let payload = {
        parentForm: this.parentFormKey,
        id: this.obj.attributes.id,
        parentBlock: this.obj.attributes.parent
      }
      eventBus.$emit('inputFocus' + payload.parentForm, { id: payload.id, parent: payload.parentBlock })
    },
    handleFocusInput (id) {
      console.log(this.$refs)
      console.log(id)
      if (this.obj.attributes.id === id && this.obj.attributes.type.toLowerCase().trim() !== 'file')
        this.$nextTick(() => {
          const inputRef = this.$refs[id]
          inputRef.focus()
        })
    },
    setParentFormKey () {
      this.parentFormKey = this.parentKey ? this.parentKey : this.$store.getters.getInsideOfFormKey(this.obj.key)
    },
    tagValidator (tag) {
      if (this.rulesObj.email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tag)
      }
    },
    setTagValidation (valid, invalid, duplicate) {
      console.log(valid, invalid, duplicate)
      if (invalid.length > 0) this.tagValidation = this.$t('GENERAL_INVALID_VALUE')
      if (duplicate.length > 0) this.tagValidation = this.$t('GENERAL_DUPLICATE_VALUE')
      if (invalid.length === 0 && duplicate.length === 0) this.tagValidation = ''
    },
    emitClick () {
      this.$emit('input-click')
    },
    setTouch () {
      this.isTouch = true
    },
    onEnter () {
      if (this.obj.attributes.submitOnEnter) {
        console.log('enter event on input')
        eventBus.$emit('submitForm' + this.parentFormKey, { hideToast: true }) // true -> hide toast after submit
      }
    },
    validateWarning () {
      let warning = this.obj.attributes.warningValidation
      if (warning) {
        warning.forEach(rule => {
            if (rule.type.toLowerCase().trim() === 'regex') {
              if (rule.pattern && !rule.pattern.test(this.innerValue)) {
                this.warnings.push(rule.translation_key_custom ? rule.translation_key_custom : rule.translation_key )
              } else this.warnings = []
            }
        })
      }
    },
    initInput () {
      this.setCustomMessages()
      this.setParentFormKey()

      let value = null
      if (this.obj.attributes.value && this.obj.attributes.type.toLowerCase().trim() === 'select' && Array.isArray(this.obj.attributes.content)) {
        let content = undefined
        if (this.innerValue.guid) content = this.obj.attributes.content.find(e => e.value.guid === this.innerValue.guid)
        else content = this.obj.attributes.content.find(e => e.value == this.innerValue)
        console.log('content')
        value = content ? content : this.innerValue
      }  else if (this.obj.attributes.type.toLowerCase().trim() === 'checkbox' && this.obj.children.length === 1 && parseFloat(this.obj.children[0].attributes.value) === 1) {
        value = this.innerValue.length ? '1' : false
      } else {
        value = this.innerValue
      }
      let payload = {
        key: this.parentFormKey,
        inputs: {
          [this.obj.attributes.name]: value
        }
      }
      this.$store.commit('setFormFilledDataByKey', payload)
      this.$nextTick(() => {
        if (this.parentFormKey) {
          eventBus.$emit('initInput' + this.parentFormKey, { key: this.obj.key, value: value })
        }
      })
    }
  },
  computed: {
    //TODO
    passed () {
      return false
    },
    setLabel () {
      if (this.customLabel) {
        return this.rulesObj && this.rulesObj.required ? this.$t(this.customLabel) + ' *' : this.$t(this.customLabel)
      } else if (this.obj.attributes.label) {
        return this.rulesObj && this.rulesObj.required ? this.$t(this.obj.attributes.label) + ' *' : this.$t(this.obj.attributes.label)
      } else if (this.obj.attributes.label == '') {
        return ''
      }
    },
    dateValue: {
      get () {
        return this.innerValue
      },
      set (date) {
        if (date) {
          if (this.obj.attributes.timepicker) {
            this.innerValue = moment(date).format('YYYY-MM-DDTHH:mm:ss')
          } else {
            this.innerValue = moment(date).format('YYYY-MM-DDT00:00:00')
          }
        } else {
          this.innerValue = ''
        }
      }
    },
    updatedContent () {
      let options = []

      if (this.obj && this.obj.attributes.type.toLowerCase() === 'select') {
        let content = this.obj.attributes.content
        if ((Array.isArray(content) && content[0])) {
          let _this = this

          function constructContent (contentObj) {
            let option = {
              value: contentObj.value,
            }
            if (contentObj.content !== undefined) {
              option['label'] = _this.$t(contentObj.text)
              option['options'] = contentObj.content.map(groupedOption => constructContent(groupedOption))
              option['text'] = _this.$t(contentObj.text)
            } else {
              option['text'] = _this.$t(contentObj.text)
            }
            return option
          }

          for (let item of content) {
            options.push(constructContent(item))
          }

        } else if (typeof content === 'object' && content !== null) {
          for (let option in content) {
            let newOption = {
              text: this.$t(content[option]),
              value: option,
              disabled: !option && this.rulesObj.required ? true : false
            }
            options.push(newOption)
          }
        } else if (content) {
          options = content
        }
      }
      return options
    },
    valueListener () {
      return this.obj.attributes.value
    },
    fieldName () {
      return this.obj.attributes.name ? this.obj.attributes.name : this.obj.attributes.placeholder
    },
    checkRules () {
      let hasRules = false
      for (var prop in this.rulesObj) {
        if (this.rulesObj.hasOwnProperty(prop)) {
          hasRules = true
        }
      }
      if (typeof this.obj.attributes.validation !== 'object' && typeof this.obj.attributes.validation == 'string') return false
      return hasRules
    },
    checkAutocomplete () {
      if (this.obj.attributes.hasOwnProperty('autocomplete')) {
        return this.obj.attributes.autocomplete
      } else {
        return 'on'
      }
    },
    labelTooltip () {
      return this.obj.attributes?.labelIcon?.tooltip ? this.$t(this.obj.attributes.labelIcon.tooltip) : undefined
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      // this.emitToForm('input', newVal)
      // this.emitToForm('handleChange', newVal)
      // this.$emit('input', newVal)
      this.$emit('handleChange', newVal)
    },
    valueListener (val) {
      console.log('value changed for ', this.obj.key, ' to ', val)
      this.innerValue = val
      this.handleChange('change')
    },
    'obj.attributes.name' (oldval, newval) {
      if (oldval != newval) this.initInput()
    },
    'obj.attributes.validation' (oldObj, newObj) {
      this.setCustomMessages()
      this.$children[0].validate()
    }
  },
  mounted () {
    this.initInput()
    if (this.obj.attributes.focusOnLoad) {
      console.log('focusOnLoad ', this.obj.attributes.id)
      this.handleFocusInput(this.obj.attributes.id)
    }
    eventBus.$on('clearInput' + this.obj.key, this.handleClearInput)
    eventBus.$on('focus-input-' + this.obj.attributes.id, this.handleFocusInput)
  },
  created () {
    if (this.obj.attributes.value || this.obj.attributes.value === 0) {
      if (this.obj.attributes.type.toLowerCase().trim() === 'checkbox') {
        if (Array.isArray(this.obj.attributes.value)) {
          this.innerValue = this.obj.attributes.value
        } else {
          if (this.obj.children.filter(item => item && item.attributes && item.attributes.value == this.obj.attributes.value).length > 0) {
            this.innerValue = this.obj.attributes.value
          } else {
            this.innerValue = []
          }
        }
      } else {
        this.innerValue = this.obj.attributes.value
      }
    } else if (this.obj.attributes.placeholder && this.obj.attributes.type.toLowerCase().trim() === 'select') {
      this.innerValue = this.obj.attributes.placeholder
    } else if (['checkbox', 'inputtags'].includes(this.obj.attributes.type.toLowerCase().trim())) {
      this.innerValue = []
    } else if (this.obj.attributes.type.toLowerCase().trim() === 'time' ||
        this.obj.attributes.type.toLowerCase().trim() === 'businesshour') {
      this.innerValue = {}
    } else {
      this.innerValue = ''
    }
  },
  beforeDestroy () {
    eventBus.$off('clearInput' + this.obj.key, this.handleClearInput)
    eventBus.$off('focus-input-' + this.obj.attributes.id, this.handleFocusInput)
  }
}
</script>

<style lang="scss">
.help {
  border: 1px solid $red;
}

.date-picker-input, .vhd-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fefdfc;
  background-clip: padding-box;
  border: 1px solid #E6E7E8;
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.error-message {
  color: $red;
  border-width: 0;
  /*text-align: right;*/
}

.warning {
  color: $font-color;
}

.input-checkbox-wrapper {
  .custom-checkbox.b-custom-control-lg {
    margin-top: 5px;
  }
}

.date-range-children {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  > div:not(:first-child) {
    margin-left: 1rem;
  }
}

.lh-input {
  .mobile {
    .calendars-container {
      display: block;
    }
  }

  .vue-daterange-picker {
    width: 100%;
  }

  .reportrange-text {
    border-color: $light-grey;
    width: 100%;
  }

  .btn-success {
    background-color: $primary-color;
    color: $white;
  }

  &__input-tags {
    padding: 0;

    .form-control {
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-left: none;
    }

    &.form-control.is-invalid {
      padding-right: 0;
      background-image: unset;
    }

    ul {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 0;
      margin: 0;
      max-height: 400px;
      overflow: auto;
      border-top: 1px solid $input-border-color;

      li {
        margin: 0.25rem 0.5rem !important;

        .card-body {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &:last-child {
          margin-top: 0.5rem !important;
        }

        &:first-child {
          margin-bottom: 0.5rem !important;
        }
      }

      &.has-error {
        li:last-child {
          border-color: $red;
        }
      }
    }
  }
}

.radio-group-wrapper {
  min-height: calc(1.5em + 0.75rem + 3px);
}

.form-group-wrapper {
  margin-bottom: 1em;

  &.remove-margin-bottom {
    margin-bottom: 0;
  }

  &.hidden {
    margin: 0;

    label {
      padding: 0;
    }
  }
}

.empty-space-placeholder {
  padding: 1.18rem 1rem !important;
}

.extra-info {
  margin: 0.5em 0;
}

.label-icon {
  margin-left: 5px;
}

.input-wrapper {
  display: flex;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

.input-icon-slot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}
</style>
