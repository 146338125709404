<template>
  <div class="chart">
    <div class="chart-line" v-if="updatedType === 'Line'">
      <line-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                  :height="obj.attributes.height" ref="chart"></line-chart>
    </div>
    <div class="chart-bar" v-else-if="updatedType === 'Bar'">
      <bar-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions" :height="obj.attributes.height"
                 ref="chart"></bar-chart>
    </div>
    <div class="chart-horizontal-bar" v-else-if="updatedType === 'HorizontalBar'">
      <horizontal-bar-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                            :height="obj.attributes.height"
                            ref="chart"></horizontal-bar-chart>
    </div>
    <div class="chart-doughnut" v-else-if="updatedType === 'Doughnut'">
      <doughnut-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                      :height="obj.attributes.height"
                      ref="chart"></doughnut-chart>
    </div>
    <div class="chart-pie" v-else-if="updatedType === 'Pie'">
      <pie-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions" :height="obj.attributes.height"
                 ref="chart"></pie-chart>
    </div>
    <div class="chart-radar" v-else-if="updatedType === 'Radar'">
      <radar-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                   :height="obj.attributes.height" ref="chart"></radar-chart>
    </div>
    <div class="chart-polar-area" v-else-if="updatedType === 'PolarArea'">
      <polar-area-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                        :height="obj.attributes.height"
                        ref="chart"></polar-area-chart>
    </div>
    <div class="chart-bubble" v-else-if="updatedType === 'Bubble'">
      <bubble-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                    :height="obj.attributes.height" ref="chart"></bubble-chart>
    </div>
    <div class="chart-scatter" v-else-if="updatedType === 'Scatter'">
      <scatter-chart :chartdata="obj.attributes.chartdata" :options="updatedOptions"
                     :height="obj.attributes.height"
                     ref="chart"></scatter-chart>
    </div>
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"
               :height="obj.attributes.height"></component>
    <div :class="['custom-legend', { vertical: obj.attributes.verticalLegend }]" v-if="hasCustomLegend" v-html="customLegendHtml"></div>
  </div>

</template>
<script>
  import LineChart from './charts/LineChart'
  import BarChart from './charts/BarChart'
  import HorizontalBarChart from './charts/HorizontalBarChart'
  import DoughnutChart from './charts/DoughnutChart'
  import PieChart from './charts/PieChart'
  import RadarChart from './charts/RadarChart'
  import PolarArea from './charts/PolarAreaChart'
  import Bubble from './charts/BubbleChart'
  import Scatter from './charts/ScatterChart'
  import ChartDataLabels from 'chartjs-plugin-datalabels'
  import { mapState } from 'vuex'

  export default {
    name: 'LhChart',
    components: {
      DoughnutChart,
      LineChart,
      BarChart,
      HorizontalBarChart,
      PieChart,
      RadarChart,
      PolarArea,
      Bubble,
      Scatter
    },
    props: {
      obj: {
        type: Object
      }
    },
    data(){
      return {
        updatedOptions: {},
        hasCustomLegend: false,
        customLegendHtml: ''
      }
    },
    computed: {
      ...mapState({
        isMobile: 'isMobile'
      }),
      updatedType () {
        this.updateData()
        return this.isMobile && this.obj.attributes.mobileType
          ? this.obj.attributes.mobileType : this.obj.attributes.type
      },
    },
    methods: {
      updateOptions() {
        function wc_hex_is_light() {
          if (this.obj.attributes?.chartdata?.datasets
            && this.obj.attributes.chartdata.datasets[0]){
            let color = this.obj.attributes.chartdata.datasets[0].backgroundColor
            const hex = color.replace('#', '');
            const c_r = parseInt(hex.substr(0, 2), 16);
            const c_g = parseInt(hex.substr(2, 2), 16);
            const c_b = parseInt(hex.substr(4, 2), 16);
            const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
            console.log(brightness)
            return brightness > 155;
          }
          return true
        }
        let option = JSON.parse(JSON.stringify(this.obj.attributes.options))
        if (option.title) option.title.text = this.$t(option.title.text)
        if (this.isMobile) {
          option['plugins'] = {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] !== 0
              },
              color: wc_hex_is_light() ? '#000000' : '#ffffff'
            }
          }
        } else {
          option['plugins'] = {
            datalabels: {
              display: null
            }
          }
        }

        //Stylin
        option['defaultFontSize'] = 15
        option['defaultFontFamily'] = 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
        option['legend'] = {
          display: false
        }
        if(this.obj.attributes.displayLegend !== false) {
          option['legendCallback'] = function (chart) {
            // Return the HTML string here.
            if (chart.data && chart.data.datasets && chart.data.datasets.length > 0) {
              var text = [];
              text.push('<ul class="' + chart.id + '-legend">');
              for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
                text.push('<li @click="updateDataSet(event, ' + '\'' + i + '\'' + ')"><a title="' + chart.data.labels[i] + '"><span id="legend-' + i + '-item" style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></span>');
                if (chart.data.labels[i]) {
                  text.push(chart.data.labels[i]);
                }
                text.push('</a></li>');
              }
              text.push('</ul>');
              return text.join("");
            }
          }
          // X axis
          if (option['scales']['xAxes'][0].ticks) {
            option['scales']['xAxes'][0].ticks.fontSize = 12
            option['scales']['xAxes'][0].ticks.fontFamily = 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
          } else {
            option['scales']['xAxes'][0].ticks = {
              fontSize: 12,
              fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
            }
          }
          // Y axis
          if (option['scales']['yAxes'][0].ticks) {
            option['scales']['yAxes'][0].ticks.fontSize = 12
            option['scales']['yAxes'][0].ticks.fontFamily = 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
          } else {
            option['scales']['yAxes'][0].ticks = {
              fontSize: 12,
              fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
            }
          }
          this.hasCustomLegend = true
        }

        this.updatedOptions = option
        if(this.obj.attributes.displayLegend !== false) {
          this.$nextTick(() => {
            this.customLegendHtml = this.$refs['chart'].generateLegend()
          })
        }
      },
      updateData () {
        if (this.$refs['chart']) {
          this.$refs['chart'].$data._chart.update()
        }
      },
      updateDataSet(e, datasetIndex) {
        var index = datasetIndex;
        var ci = e.view.myDoughnut;
        var meta = ci.getDatasetMeta(0);
        var result= (meta.data[datasetIndex].hidden == true) ? false : true;
        if(result==true)
        {
          meta.data[datasetIndex].hidden = true;
          $('#' + e.path[0].id).css("text-decoration", "line-through");
        }else{
          $('#' + e.path[0].id).css("text-decoration","");
          meta.data[datasetIndex].hidden = false;
        }

        ci.update();
      }
    },
    watch: {
      isMobile() {
        this.updateOptions()
      }
    },
    mounted(){
      this.$nextTick(() => {
        this.updateOptions()
      })
    }
  }
</script>

<style lang="scss" scoped>
  .chart {
    & > div {
      &.chart-doughnut {
        padding: 0 2rem;
        @media (min-width: 1440px) {
          padding: 0 4rem;
        }
      }
    }
  }
</style>
<style lang="scss">
  .custom-legend {
    height:auto;
    width:100%;
    & > ul{
      display: flex;
      align-items: center;
      padding: 0;
      text-align: center;
      li {
        cursor: pointer;
        margin: 4px 3px;
        display: inline-block;
        text-overflow: ellipsis;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        a {
          color: $font-color;
          display: block;
        }
        &::marker {
          display: none;
        }
        &:last-child {
          margin-bottom: 0;
        }
        span {
          position: relative;
          width: 1rem;
          height: 1rem;
          margin: 0 .5rem 0 0;
          border-radius: 13px;
          color: white;
          z-index: 2;
          font-size: 11px;
          display: inline-block;
          top: 1px;
        }
      }
    }
    &:not(.vertical){
      ul {
        flex-wrap: wrap;
        li {
          width: calc(33% - 6px);
        }
      }
    }
    &.vertical {
      ul {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
</style>
