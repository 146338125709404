<template>
  <div class="drag-handle-wrapper" :style="checkStyles" v-show="tabActive">
    <div :class="['drag-handle', {'is-draggable': isDraggable}]" >
      <div class="popup-title">
        {{ $t(obj.attributes.content) }}
        <lh-edit-translation :obj="{key: obj.attributes.content, parentKey: obj.key}"></lh-edit-translation>
      </div>
    </div>
    <div class="right-content">

      <!-- collapse menu: only show if buttons present (children) -->
      <b-collapse id="header-icons" v-model="visible" class="ml-auto" v-if="obj.children.length > 0">
        <div class="collapse-menu">
          <div class="collapse-menu-item">
            <template v-for="child in obj.children">
              <component :is="child.type" :key="child.key" :obj="child"></component>
              <span class="item-label d-md-none">{{ $t(child.content) }}</span>
            </template>
          </div>
        </div>
      </b-collapse>

      <!-- collapse menu trigger: only show if buttons present (children) -->
      <b-button
          v-if="obj.children && obj.children.length > 0"
          :class="[visible ? null : 'collapsed', 'd-md-none', 'header-icons-button']"
          variant="primary"
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="header-icons"
          @click.stop="visible = !visible"
      >
        <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
      </b-button>
      <div class="close-button" @click.stop="emitAction('toggleFullscreen')" v-if="!isMobile && resizable">
        <font-awesome-icon class="closeBtn"
                           :icon="['fal', 'expand-alt']"
                           v-if="!popupFullscreen"
        ></font-awesome-icon>
        <font-awesome-icon class="closeBtn"
                           :icon="['fal', 'compress-alt']"
                           v-if="popupFullscreen"
        ></font-awesome-icon>
      </div>
      <!--      close button: always show!-->
      <div class="close-button" @click.stop="emitAction('closePopup')" v-on="closeActions(this.obj.attributes.closeActions)">
        <font-awesome-icon class="closeBtn"
                           :icon="['fal', 'times']"
                           v-if="!(popupFullscreen && showSidebar) || resizable"
        ></font-awesome-icon>
        <font-awesome-icon class="closeBtn"
                           :icon="['fas', 'arrow-circle-left']"
                           v-else
        ></font-awesome-icon>
      </div>
    </div>
  </div>
</template>

<script>
import mixinFunctions from '@/mixin/mixinFunctions'
import confirm from '@/mixin/confirm'
import { mapState } from 'vuex'
import { eventBus } from '../services/event-bus'

export default {
  name: 'LhPopupHeader',
  mixins: [mixinFunctions, confirm],
  props: {
    obj: {
      type: Object,
      default () {
        return {
          attributes: {
            content: ''
          }
        }
      }
    },
    popupFullscreen: {
      type: Boolean
    },
    isDraggable: {
      type: Boolean
    },
    resizable: {
      type: Boolean
    },
    showSidebar: {
      type: Boolean
    }
  }
  ,
  data () {
    return {
      visible: true,
      activeTab: undefined
    }
  },
  computed: {
    ...mapState({
      isMobile: 'isMobile'
    }),
    tabActive () {
      if (this.activeTab !== undefined) {
        return this.activeTab.key === this.obj.attributes.target
      } else {
        return true
      }
    }
  },
  methods: {
    emitAction (action) {
      this.$emit(action)
    },
    setVisibility () {
      this.isMobile ? this.visible = false : this.visible = true
    },
    handleTabChange (payload) {
      if (payload.target === this.obj.attributes.parent) {
        this.activeTab = payload
      }
    },
    closeActions(actions) {
      let events = {}
      if (actions) {
        for (const action of actions) {
          events[action.event] = this.allEventHandler(action)
        }
      }
      return events
    }
  }
  ,
  watch: {
    mobile () {
      this.setVisibility()
    }
  },
  mounted () {
    this.setVisibility()
    eventBus.$on('changedTab', this.handleTabChange)
  },
  beforeDestroy () {
    eventBus.$off('changedTab', this.handleTabChange)
  }
}
</script>

<style scoped lang="scss">
.drag-handle-wrapper {
  background: $popup-header-bg;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  min-height: $popup-header-height;
}

.drag-handle {
  color: white;
  font-size: 1.2em;
  flex-grow: 1;

  &.is-draggable {
    cursor: move;
  }

  .popup-title {
    padding: 0.85rem 0.75rem 0.85rem 2rem;
    font-weight: 700;
    font-size: $h1-font-size;
  }
}

.right-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.close-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  height: 100%;
  padding: 0.5rem 1.2rem;
  z-index: 10;
}

.collapse {
  width: auto;

  .icon-button {
    font-size: 18px;
    margin-right: 0.75em;
    padding: 1rem;
    @include media-breakpoint-up(md) {
      color: $white;
      padding: 0.2em;
    }
  }

  &-menu {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    top: 100%;
    right: 2rem;
    background-color: $white;
    padding: 0.5em;
    box-shadow: $popup-shadow;
    min-width: 200px;
    max-width: 100vw;

    .collapse-menu-item {
      color: $font-color;
      display: flex;
      align-items: center;
      padding-right: 1.5rem;

      &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;
        &:not(:last-child) {
          border-color: transparent;
        }

      }
    }

    @include media-breakpoint-up(md) {
      background-color: $popup-header-bg;
      padding: 0;
      position: relative;
      box-shadow: none;
      top: initial;
      right: initial;
      flex-flow: row nowrap;
      min-width: auto;
    }
  }
}

#header-icons {
  display: flex;
}

.header-icons-button {
  background-color: $popup-header-bg;
  border-color: $popup-header-bg;
  color: $popup-header-color;

  &:active, &:hover, &:focus {
    background-color: $popup-header-bg;
    border-color: $popup-header-bg;
    color: $popup-header-color;
  }
}
</style>
