<template>
  <div :class="[checkClasses, 'filter-control']" :style="checkStyles" v-tooltip="checkTooltip">
    <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
    <template v-if="activeFilters">
      <template v-for="(value, key) in activeFilters">
        <div v-if="!Array.isArray(value) && !isEmptyOrEmptyArray(value)" class="filter-target">
          <div  class="remove-btn" @click="removeFilter(key)">
            <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
            <template>{{ getValue(value) }}</template>
          </div>
        </div>
        <div v-else-if="Array.isArray(value)" v-for="(subValue, index) in value">
          <div v-if="!isEmptyOrEmptyArray(subValue)" class="filter-target">
            <div class="remove-btn" @click="removeSubFilter(key, index)">
              <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
              <template>{{ getValue(subValue) }}</template>
            </div>
          </div>
        </div>
      </template>
      <div v-if="!isEmpty" class="filter-target">
        <div class="remove-btn" @click="removeAllFilter">
          <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
          {{$t('GENERAL_CLEAR_ALL')}}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import moment from 'moment'

  export default {
    name: 'LHTableFilterControl',
    props: {
      obj: {
        type: Object
      }
    },
    data () {
      return {}
    },
    computed: {
      ...mapState({
        filterData: 'filterData',
        activeFilterData: 'activeFilterData'
      }),
      filters () {
        let filter = null
        if (this.filterData.length) {
          filter = this.filterData.find(item => item.key === this.obj.attributes.target)
        }
        return filter ? filter.inputs : null
      },
      activeFilters () {
        let filter = null
        if (this.activeFilterData.length) {
          filter = this.activeFilterData.find(item => item.key === this.obj.attributes.target)
        }

        return filter ? filter.inputs : null
      },
      isEmpty () {
        let count = 0
        for (var key in this.activeFilters) {
          if (this.activeFilters.hasOwnProperty(key) && this.activeFilters[key] !== "") {
            count++
          }
        }
        return count > 0 ? false : true
      }
    },
    methods: {
      ...mapMutations({
        setFilterData: 'setFilterDataByKey',
        setActiveFilter: 'setActiveFilterData'
      }),
      ...mapActions({
        sortAndFilter: 'sortAndFilter'
      }),
      removeFilter (value) {
        console.log('removing filter', value)
        this.$delete(this.filters, value)
        this.$delete(this.activeFilters, value)
        console.log('removing filter 2', this.filters, this.activeFilters)

        this.sortAndFilter({
          target: this.obj.attributes.target,
          url: this.obj.attributes.action,
          browseKey: this.obj.attributes.target,
          pressedBtn: ''
        })
      },
      removeSubFilter (key, index) {
        console.log('removing filter', key, index)
        if(Object.keys(this.filters[key]).length > 1) {
          this.$delete(this.filters[key], index)
          this.$delete(this.activeFilters[key], index)
        } else {
          this.$delete(this.filters, key)
          this.$delete(this.activeFilters, key)
        }
        this.sortAndFilter({
          target: this.obj.attributes.target,
          url: this.obj.attributes.action,
          browseKey: this.obj.attributes.target,
          pressedBtn: ''
        })
      },
      removeAllFilter () {
        for (let key in this.filters) {
          this.$delete(this.filters, key)
          this.$delete(this.activeFilters, key)
        }
        this.sortAndFilter({
          target: this.obj.attributes.target,
          url: this.obj.attributes.action,
          browseKey: this.obj.attributes.target,
          pressedBtn: ''
        })
      },
      isEmptyOrEmptyArray (value) {
        let isEmpty = true
        if (Array.isArray(value)) {
          if (value.length > 0 ) {
            isEmpty = false
          }
        } else {
          if (value && value.hasOwnProperty('value') && value.value == '') {
            isEmpty = true
          } else if (value) {
            isEmpty = false
          }
        }
        return isEmpty
      },
      getValue(value) {
        if (value) {
          if (value.hasOwnProperty('start') && value.hasOwnProperty('end')) {
            let start = value.start ? moment(value.start, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY') : ''
            let end = value.end ? moment(value.end, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY') : ''
            return start + ' ~ ' + end
          } else if (value.hasOwnProperty('text')) {
            return this.$t(value.text)
          } else if (moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
            return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
          } else if(Array.isArray(value)) {
            let val = ''
            for(var i=0; i<value.length; i++){
              if(typeof value[0] !== 'undefined' && value[0].hasOwnProperty('text')) {
                val += ', ' + this.$t(value[i].text)
              }
            }
            return val != '' ? val.substring(2) : this.$t(value.toString())
          }
          return this.$t(value)
        }
      }
    },
    mounted () {
      if (this.obj.attributes.filters) {
        let showFilter = this.obj.attributes.showActiveFilters

        let filters = this.obj.attributes.filters
        let inputs = showFilter ? filters : {}
        let defaultFilters = showFilter ? {} : filters
        this.setFilterData({
          key: this.obj.attributes.target,
          inputs: inputs,
          defaultFilters: defaultFilters
        })
        if (showFilter) this.setActiveFilter(this.obj.attributes.target)
      }

      if (this.obj.attributes.activeFilters) {
        let showFilter = this.obj.attributes.showActiveFilters
        let filters = this.obj.attributes.activeFilters
        let inputs = showFilter ? filters : {}
        let defaultFilters = showFilter ? {} : filters
        this.setFilterData({
          key: this.obj.attributes.target,
          inputs: inputs,
          defaultFilters: defaultFilters
        })
        if (showFilter) this.setActiveFilter(this.obj.attributes.target)
      }
    }
  }
</script>

<style scoped lang="scss">
  .filter-control {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .filter-target {
    color: $white;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 700;
    border: 1px solid $filter-tag-color;
    background-color: $filter-tag-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.5em;
    border-radius: 0.8em;
    margin: 0.25rem 0;

    & + div {
      margin-left: 0.5em;
    }
  }

  .remove-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.2em 0.5em 0.25em 0.5em;
    border-top-left-radius: 0.75em;
    border-bottom-left-radius: 0.75em;

    svg {
      margin-right: 6px;
    }

    &:hover, &:focus, &:active {
      color: darken($filter-tag-color, 25%);
    }
  }

  .button-wrapper {
    padding-bottom: 0;
  }
</style>